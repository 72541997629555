<template>
  <div id="home">
    <articles />
    <!-- <banner /> -->

    <about />

    <!-- <subscribe /> -->
    <!-- <social /> -->
  </div>
</template>

<script>
  export default {
    name: 'Home',

    components: {
      About: () => import('@/components/home/About'),
      Articles: () => import('@/components/home/Articles'),
    },

  }
</script>
