import { getCountList } from './../plugins/firebase'
export default {
  namespaced: true,
  state: {
    today: 0,
    total: 0,
    totalTry: 0,
    allCount: [

    ],
  },
  getters: {
    getTodayCount (state) {
      return state.today
    },
    getTotalCount (state) {
      return state.total
    },
    getTotalTry (state) {
      return state.totalTry
    },
    async getAllCount (state) {
      return await { todayVisit: state.today, totalVisit: state.total, totalTry: state.totalTry }
    },
  },
  mutations: {
    setTodayCount (state, today) {
      state.today = today
    },
    setTotalCount (state, total) {
      state.total = total
    },
    setTotalTry (state, totalTry) {
      state.totalTry = totalTry
    },
    setAllCount (state, allCount) {
      return state.allCount
    },
  },
  actions: {
    async fetchCount ({ commit }) {
      const res = await getCountList()

      const today = res.todayVisit
      await commit('setTodayCount', today)

      const total = res.totalVisit
      await commit('setTotalCount', total)

      const totalTry = res.totalTry
      await commit('setTotalTry', totalTry)
    },
  },
}
