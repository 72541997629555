import answer from '../data/answer'
import resultdata from '../data/resulttype'

export default {
  namespaced: true,
  state: {
    resulttype: [],
    selectKey: [],
    selectValue: [],
    mbti: [],
    abcd: ['IE', 'NS', 'TF', 'PJ'],
    scoreList: [0, 1, 2, 3, 4, 5],
    choiceCount: [2, 3, 4, 5],
  },
  getters: {
    getResultType(state) {
      return state.resulttype
    },
    getSelectKey(state) {
      return state.selectKey
    },
    getSelectValue(state, getters) {
      return state.selectValue
    },
    getMBTI(state) {
      return state.mbti
    },
    getScoreList(state) {
      return state.scoreList
    },
    getChoiceCount(state) {
      return state.choiceCount
    },
  },
  mutations: {
    setResultType(state, resulttype) {
      state.resulttype = resulttype
    },
    setSelectKey(state, selectKey) {
      state.selectKey = selectKey
    },
    setSelectValue(state, selectValue) {
      state.selectValue = selectValue
    },
    setMBTI(state, mbti) {
      state.mbti = mbti
    },
  },
  actions: {
    fetchResultType({ commit }) {
      commit('setResultType', resultdata.resultType)
    },
    fetchSelectKey({ commit }) {
      commit('setSelectKey', resultdata.selectKey)
    },
    fetchSelectValue({ commit }) {
      commit('setSelectValue', resultdata.selectValue)
    },
    fetchMBTI({ commit }) {
      commit('setMBTI', answer.value)
    },
  },
}
