import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#72BDC0',
        secondary: '#083759',
        bg: '#2B4855',
        yellow: '#EAC26C',
        red: '#EA6F55',
      },
    },
  },
})
