<template>
  <div id="home">
    <pretest />
    <!-- <about /> -->
  </div>
</template>

<script>
  export default {
    name: 'Sample',

    components: {
      Pretest: () => import('@/components/test/PreTest'),
    },
  }
</script>
