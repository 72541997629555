import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/Home.vue'
import Ranking from '../views/Ranking.vue'
import Sample from '../views/Sample.vue'
import Create from '../views/Create.vue'
import Sign from '../views/Sign.vue'
import Result from '../views/Result.vue'
import AddQues from '../views/AddQues.vue'
import AddResults from '../views/AddResults.vue'
import AddScoreResults from '../views/AddScoreResults.vue'
import MyPage from '../views/MyPage.vue'
import Admin from '../views/Admin.vue'
import History from '../views/History.vue'
import store from '@/store'
Vue.use(Router)

const requireAuth = () => (from, to, next) => {
  const isAuthenticated = sessionStorage.getItem('try-me')
  // const isAuthenticated = store.getters['user/hasUserInfo']
  if (isAuthenticated) return next()
  next('/sign')
}

const requireAdminAuth = () => (from, to, next) => {
  const isAuthenticated = sessionStorage.getItem('admin')
  // const isAuthenticated = store.getters['user/isAdmin']
  if (isAuthenticated) return next()
  next('/sign')
}

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/ranking',
      name: 'ranking',
      component: Ranking,
    },
    {
      path: '/test/sample',
      name: 'sample',
      component: Sample,
    },
    {
      path: '/test/:themeId',
      name: 'sample',
      component: Sample,
    },
    {
      path: '/create',
      name: 'create',
      component: Create,
      beforeEnter: requireAuth(),
    },
    {
      path: '/add',
      name: 'add',
      component: AddQues,
      beforeEnter: requireAuth(),
    },
    {
      path: '/addresult/:themeId',
      name: 'addresults',
      component: AddResults,
      beforeEnter: requireAuth(),
    },
    {
      path: '/addscoreresult/:themeId',
      name: 'addscoreresults',
      component: AddScoreResults,
      // beforeEnter: requireAuth(),
    },
    {
      path: '/sign',
      name: 'sign',
      component: Sign,
    },
    {
      path: '/signup',
      name: 'signup',
      component: Sign,
    },
    {
      path: '/result/:themeId/:resultid',
      name: 'result',
      component: Result,
    },
    {
      path: '/mypage',
      name: 'mypage',
      component: MyPage,
      beforeEnter: requireAuth(),
    },
    {
      path: '/admin',
      name: 'admin',
      component: Admin,
      beforeEnter: requireAdminAuth(),
    },
    {
      path: '/history',
      name: 'history',
      component: History,
      beforeEnter: requireAdminAuth(),
    },
  ],
})
