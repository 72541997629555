<template>
  <v-container>
    <v-card>
      <v-expansion-panels>
        <v-expansion-panel
          v-for="(item, i) in list"
          :key="i"
        >
          <v-expansion-panel-header>
            {{ item.title }} / {{ item.date }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            {{ item.content }}
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          title: 'aa',
          date: 'b',
          content: 'c',
        },
      ],
    }
  },
}
</script>

<style></style>
