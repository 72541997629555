import Vue from 'vue'
import vuetify from './plugins/vuetify'
import InfiniteLoading from './plugins/InfiniteLoading'
import kakao from './plugins/kakao'
import firebase from './plugins/firebase'
import './plugins/base'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/style/main.scss'
// import Ads from 'vue-google-adsense'
import Vue2Editor from 'vue2-editor'
//

Vue.config.productionTip = false

// Vue.use(require('vue-script2'))
// Vue.use(Ads.Adsense)
// Vue.use(Ads.InArticleAdsense)
// Vue.use(Ads.InFeedAdsense)

new Vue({
  vuetify,
  firebase,
  router,
  store,
  kakao,
  Vue2Editor,
  InfiniteLoading,
  render: h => h(App),
}).$mount('#app')
