<template>
  <v-container>
    <v-card class="mx-auto">
      <v-card-text align="center">
        <v-card-subtitle>트라이미</v-card-subtitle>
        <v-img
          src="https://firebasestorage.googleapis.com/v0/b/tryme-eeb82.appspot.com/o/ye.png?alt=media&token=275b7fae-e902-430a-88ac-deabe88653fa"
          aspect-ratio="2"
        />
        <p class="text-h4 text--primary mt-5">
          {{ title }}
        </p>
        <div
          v-if="!Number($route.params.resultid)"
          class="text--primary text-h5"
          v-html="result.content"
        />
        <div
          v-if="$store.state.qna.qs.qlist[0].typeOf == 'OX'"
          class="text--primary text-h5"
        >
          a 당신은.. 무려무려 <strong>{{ $route.params.resultid }}</strong
          >점입니다!<br />
        </div>
        <div
          v-if="$store.state.qna.qs.qlist[0].selectKey == 0"
          class="text--primary text-h5"
          v-html="result.content"
        >
          {{ result }}
          b 당신은.. 무려무려 <strong>{{ $route.params.resultid }}</strong
          >점입니다!<br />
        </div>
      </v-card-text>
      <v-card-text
        align="center"
        class="text-center mt-10"
      >
        <v-row justify="space-around">
          <v-col>
            <v-btn
              v-if="$store.state.qna.qs.qlist[0].typeOf == 'OX'"
              class="ma-3"
              color="red"
              dark
              @click="checkCorrect"
            >
              정답 확인하기
            </v-btn>
            <v-btn
              class="ma-3"
              @click="retest"
            >
              테스트 다시하기
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="ma-0 pd-0 mx-auto">
          <v-col class="d-flex flex-column">
            <v-icon
              class="ma-1 pd-0 justify-end"
              x-large
              color="#1DA1F2"
              @click="shareToTwit"
            >
              mdi-twitter
            </v-icon>
          </v-col>
          <v-col>
            <v-img
              :src="require('@/assets/sns/kakaoshare.png')"
              class="ma-0 pd-0"
              contain
              height="50"
              width="50"
              max-width="50"
              @click="shartToKakao"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <!-- modal -->
      <div class="text-center">
        <v-dialog
          v-model="dialog"
          width="400"
        >
          <v-card>
            <v-alert
              class="text-center"
              style="margin-bottom: 0px"
            >
              <v-list
                v-for="item in $store.state.qna.qs.qlist"
                :key="item.id"
              >
                {{ item.q }}
                <!-- {{ this.$store.state.qna.qs.qlist }} -->
                <!-- [i].q, OorX -->
                [ <strong> {{ item.OorX }} </strong>]
              </v-list>
            </v-alert>
          </v-card>
        </v-dialog>
      </div>
      <!-- modal end -->
      <v-card class="mt-10">
        <v-card-text class="text-h5 text--primary">
          다른 테스트도 해보기
        </v-card-text>

        <list />
      </v-card>
      <v-container v-if="false">
        <v-card max-width="200px">
          <v-card-text>
            <v-img
              class="text-h5"
              aspect-ratio="1"
              max-height="400"
              max-width="400"
              :src="thumburl"
            />
          </v-card-text>
          <v-card-title
            class="text-h5 ma-1 pa-2 pt-4"
            v-text="title"
          />
          <v-card-subtitle class="text-subtitle-1 ma-1 pa-2 pt-1 pb-3">
            <strong>{{ this.count }}</strong
            >명이 이 테스트를 해봤어요!
          </v-card-subtitle>
          <v-card-actions>
            <v-btn
              text
              class="text-subtitle-1 ma-1 pa-0"
              color="teal primary-8"
            >
              테스트 하기
              <v-icon> mdi-arrow-right-bold </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import { mapMutations } from 'vuex'
import { getResult } from './../plugins/firebase'
export default {
  components: {
    List: () => import('@/components/ranking/List'),
  },
  data() {
    return {
      result: '',
      title: '',
      content: '',
      count: 0,
      thumburl: '',
      dialog: false,
    }
  },
  async mounted() {
    this.setLoading(false)

    await this.$store.dispatch('qna/fetchQs', {
      themeId: this.$route.params.themeId,
    })
    const res = await getResult({
      themeId: this.$route.params.themeId,
      result: this.$route.params.resultid,
    })

    console.log('res:', res)

    if (res && res[0].type == 'SCORE') {
      const myScore = Number(this.$route.params.resultid)
      const index = res.findIndex(item => {
        return item.from <= myScore && myScore <= item.to
      })

      this.result = res[index]
    } else {
      this.result = res[0]
    }

    const dist = this.$store.state.qna.qs.info
    this.qlist = this.$store.state.qna.qs.qlist

    this.title = dist.title
    this.count = dist.count
    this.content = dist.content
    this.thumburl = dist.thumburl
    this.totalCount = this.qlist.length
  },
  methods: {
    ...mapMutations('common', ['setLoading']),
    shartToKakao: function () {
      window.Kakao.Link.sendDefault({
        objectType: 'text',
        text: `${this.title}의 결과는 ${this.$route.params.resultid}입니다. [자세히 보기]를 눌러 상세 내용을 확인하세요 !`,
        link: {
          mobileWebUrl: `https://tryme.page/result/${this.$route.params.themeId}/${this.$route.params.resultid}`,
          webUrl: `https://tryme.page/result/${this.$route.params.themeId}/${this.$route.params.resultid}`,
        },
      })
    },
    shareToTwit() {
      const sendText = `당신의 테스트 결과는 ${this.$route.params.resultid}입니다. Tryme의 테스트 결과 공유하기입니다. [자세히 보기]를 눌러 상세 내용을 확인하세요 !`
      const sendUrl = `https://tryme.page/result/${this.$route.params.themeId}/${this.$route.params.resultid}`
      window.open(
        'https://twitter.com/intent/tweet?text=' + sendText + '&url=' + sendUrl
      )
    },
    retest() {
      this.$router.push('/test/' + this.$route.params.themeId)
    },
    checkCorrect() {
      this.dialog = true
    },
  },
}
</script>

<style></style>
