<template>
  <v-container>
    <v-card
      class="mx-auto"
      max-width="344"
    >
      <v-card-text>
        <p class="text-h5 text--primary">
          {{ $store.state.user.user.email }} 님
        </p>

        <div>반갑습니다.</div>
        <p>회원만 테스트를 제작할 수 있어요.</p>
        <div class="text--primary">
          문의사항은 test@tryme.page 으로 메일을 보내주세요<br />
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          text
          color="deep-purple accent-4"
        >
          내가 만든 테스트
          <!-- 비밀번호 재설정 (미구현ㅎㅎ) -->
        </v-btn>

        <v-btn
          text
          color="deep-purple accent-4"
        >
          내가 해본 테스트
          <!-- 비밀번호 재설정 (미구현ㅎㅎ) -->
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  data() {
    return {}
  },
  created() {},
  mounted() {
    this.$store.dispatch('user/fetchUserInfo')
  },
  methods: {
    ...mapActions('user', ['getUser']),
  },
}
</script>

<style></style>
