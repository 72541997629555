import Vue from 'vue'
import Vuex from 'vuex'
// import test from './test'
// import user from './user'
import common from './common'
import qna from './qna'
import user from './user'
import data from './data'
import count from './count'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        // test,
        // user,
        common,
        qna,
        user,
        data,
        count,
    },
})
