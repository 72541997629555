<template>
  <div id="ranking">
    <!-- <articles /> -->
    <!-- <about /> -->
    <list />
  </div>
</template>

<script>
  export default {
    name: 'Ranking',

    components: {
      List: () => import('@/components/ranking/List'),
      // About: () => import('@/components/home/About'),
      // Articles: () => import('@/components/home/Articles'),
    },
  }
</script>
