<template>
  <v-container>
    <div class="text-center">
      <v-dialog
        v-model="dialog2"
        width="400"
      >
        <v-card>
          <v-alert
            class="text-center"
            text
            type="success"
            style="margin-bottom: 0px"
          >
            <strong> 삭제 성공! </strong>
          </v-alert>
        </v-card>
      </v-dialog>
    </div>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="testRankingData"
      :single-select="singleSelect"
      item-key="item"
      show-select
      class="elevation-1"
      :sort-by="['count', 'pd']"
      :sort-desc="[true]"
      @dblclick:row="goToTest"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>My CRUD</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          />
          <v-spacer />
          <v-dialog
            v-model="dialog"
            max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                to="/create"
              >
                New Item
              </v-btn>
            </template>
            <v-card>
              <!-- <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title> -->

              <!-- <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="editedItem.name"
                        label="Dessert name"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="editedItem.calories"
                        label="Calories"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="editedItem.fat"
                        label="Fat (g)"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="editedItem.carbs"
                        label="Carbs (g)"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="editedItem.protein"
                        label="Protein (g)"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text> -->

              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="save"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="dialogDelete"
            max-width="500px"
          >
            <v-card>
              <v-card-title class="text-h5">
                이 테스트를 정말 지우시겠습니까?
              </v-card-title>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="blue darken-1"
                  text
                  @click="closeDelete"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="deleteItemConfirm"
                >
                  OK
                </v-btn>
                <v-spacer />
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template #item.action="{ item }">
        <!-- <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon> -->
        <v-icon @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      editedIndex: -1,
      editedItem: {
        name: '',
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
      defaultItem: {
        name: '',
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
      dialog: false,
      dialog2: false,
      dialogDelete: false,
      singleSelect: false,
      selected: [],
      headers: [
        {
          // align: 'start',
          text: '테마',
          value: 'title',
          width: '50%',
        },
        {
          text: '누적',
          value: 'count',
          sortable: true,
          width: '20%',
        },
        {
          text: '제작자',
          value: 'pd',
          width: '20%',
        },
        {
          text: 'action',
          value: 'action',
          width: '10%',
          sortable: false,
        },
      ],
      testRankingData: [],
    }
  },
  computed: {
    // formTitle () {
    //   return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    // },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },
  mounted() {
    this.$store.dispatch('qna/fetchRanking')
    this.testRankingData = this.$store.getters['qna/getRanking']
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.testRankingData.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.testRankingData.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    async deleteItemConfirm(id) {
      const res = await this.$store.dispatch(
        'qna/deletetheme',
        this.editedItem.id
      )

      if (res == 'SUCCESS') {
        this.dialog2 = true
        setTimeout(() => {
          this.dialog2 = false
        }, 1500)
      }

      this.testRankingData.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.testRankingData[this.editedIndex], this.editedItem)
      } else {
        this.testRankingData.push(this.editedItem)
      }
      this.close()
    },
    goToTest(event, { item }) {
      this.$router.push('/test/' + item.id)
    },
  },
}
</script>

<style></style>
