
import { FindUserList, getUserInfo, signup, signin, logout } from './../plugins/firebase'
export default {
  namespaced: true,
  state: {
    userlist: [],
    user: {
      uid: '',
      email: '',
    },
    hasLocalStorageUserInfo: false,
    isAdmin: false,

  },
  getters: {
    getUserList (state) {
      return state.userlist
    },
    hasUserInfo (state) {
      return state.hasLocalStorageUserInfo
    },
    isAdmin (state) {
      return state.isAdmin
    },
    getUser (state) {
      return state.user
    },
  },
  mutations: {
    setUserList (state, userlist) {
      state.userlist = userlist
    },
    setUserInfo: (state, bool) => (state.hasLocalStorageUserInfo = bool),
    setUser: (state, docdata) => (state.user = docdata),
    setAdmin: (state, bool) => (state.isAdmin = bool),
  },
  actions: {
    fetchUserList (context) {
      FindUserList().then(res => {
        context.commit('setUser', res)
      })
      .catch(e => {
        console.log(e)
      })
    },
     async fetchUserInfo (context) {
      try {
        const data = await getUserInfo()

        if (data) {
          context.commit('setUser', data[0])
        }
      } catch (e) {
        console.log(e)
      }
    },
    async addUser ({ commit }, payload) {
        const newUser = {
            email: payload.email,
            password: payload.password,
        }
        const res = await signup(newUser)
        commit('setUserInfo', true)
        return res
    },
    async login ({ commit }, payload) {
      const user = {
          email: payload.email,
          password: payload.password,
      }
      const res = await signin(user)

      if (res.code == 'SUCCESS') {
        if (res.user.email == 'test@test.com') {
          commit('setAdmin', true)
        }
        commit('setUserInfo', true)
        commit('setUser', { email: res.user.email, uid: res.user.uid })
        return 'SUCCESS'
      } else if (res.code == 'ERROR') {
        return 'ERROR'
      }
    },
    logoutlogout ({ commit }) {
      commit('setUserInfo', false)
      logout()
    },
  },
}
