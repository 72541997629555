<template>
  <v-container>
    <v-card>
      <v-card-title> 공통영역 </v-card-title>
      <v-card-text>
        <editors
          :typename="'temp'"
          :set-template="contents"
        />
      </v-card-text>
      <v-container>
        <v-btn
          block
          append
          @click="copyTemplate"
        >
          공통영역 내용을 아래에 모두 적용 시키기
        </v-btn>
      </v-container>
    </v-card>
    <v-card
      v-for="item in type"
      :key="item"
    >
      <v-card-title>
        {{ item }}
      </v-card-title>
      <v-card-text>
        <editors
          :typename="item"
          :set-template="setTemplate"
        />
      </v-card-text>
    </v-card>
    <v-btn @click="saveResult"> 저장 </v-btn>
  </v-container>
</template>

<script>
import answer from '@/data/answer'
import { addResult } from './../plugins/firebase'
import { mapMutations } from 'vuex'
export default {
  components: {
    Editors: () => import('@/components/create/Editor'),
  },
  data() {
    return {
      type: [],
      contents: '',
      setTemplate: '',
      list: [],
      localStorageData: '',
    }
  },
  mounted() {
    this.type = answer.value

    const getLocaltheme = JSON.parse(localStorage.getItem('theme'))

    this.contents = `당신의 ${getLocaltheme.title}의 결과는... ! <br> @MBTITYPE@ 입니다.<br>이 유형은 ((자발적 아싸 기질이 다분하여 혼자 시간을 보내는 경우가 많지만 남들보다 외로움을 느끼지는 않습니다.)) `
  },
  methods: {
    ...mapMutations('common', ['setLoading']),
    copyTemplate() {
      this.localStorageData = localStorage.getItem('MBTI_temp')
      this.setTemplate = this.localStorageData
    },
    saveResult() {
      // 로딩
      this.setLoading(true)
      this.type.forEach((item, index) => {
        const typename = 'MBTI_' + item
        const localStorageData = localStorage.getItem(typename)
        addResult({
          result: item,
          content: localStorageData,
          theme: this.$route.params.themeId,
        })
        // this.list.push({ result: item, content: localStorageData , theme: this.$route.params.themeId} )
      })
      setTimeout(() => {
        this.setLoading(false)
        this.$router.push(`/test/${this.$route.params.themeId}`)
      }, 2000)
    },
  },
}
</script>

<style></style>
