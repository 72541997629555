<template>
  <v-container>
    <!-- <div id="ranking"> -->
    <!-- <articles /> -->
    <!-- <about /> -->
    <forms />
    <!-- </div> -->
  </v-container>
</template>

<script>
  export default {
    name: 'Create',

    components: {
      //   Dropzone: () => import('@/components/create/Dropzone'),
      Forms: () => import('@/components/create/Forms'),
      // About: () => import('@/components/home/About'),
      // Articles: () => import('@/components/home/Articles'),
    },
  }
</script>
