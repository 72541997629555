<template>
  <v-container>
    <v-card>
      <v-container>
        <v-form>
          <v-row class="ma-3">
            <v-col cols="12">
              <v-select
                v-model="type"
                label="유형 수 "
                :items="[2, 3, 4, 5, 6, 7]"
              />
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col>
              점수 범위를 입력해주세요! <br />
              예시 : 0점부터 9점까지 A유형 , 10점부터 19점까지 B유형 <br />
            </v-col>
          </v-row> -->
          <v-container>
            <v-card-title style="background-color: lightsteelblue">
              공통영역
            </v-card-title>
            <v-card-text style="background-color: lightsteelblue">
              (@유형이름템플릿@ 을 그대로 유지하시면 '결과 유형' 입력시
              반영됩니다.)
            </v-card-text>
            <v-card-text style="background-color: lightsteelblue">
              <editors
                style="background-color: white"
                :typename="'temp'"
                :set-template="contents"
              />
            </v-card-text>
            <v-btn
              block
              append
              @click="copyTemplate"
            >
              공통영역 내용을 아래에 모두 적용 시키기
            </v-btn>
          </v-container>
          <v-row
            v-for="i in typeArray"
            :key="i"
          >
            <v-card class="ma-2 pa-3">
              <add-score-result
                :score-result-setting="i"
                @minusType="minusTypeForm"
              />
              <!-- 후... 결과 유형을 공통영역 복사로 복붙하고싶은데 잘안돼 .. -->
              <editors
                :typename="'SCORE' + i"
                :set-template="setTemplate"
              />
            </v-card>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-btn @click="plusType"> 추가 </v-btn>
            <v-card-text>
              삭제하고 싶은 항목은 '부터' '까지' '결과 유형'을 공백으로 수정한
              뒤 저장!
            </v-card-text>
          </v-row>
        </v-form>
      </v-container>
    </v-card>

    <v-container>
      <v-btn
        color="primary"
        block
        @click="saveResult"
      >
        저장
      </v-btn>
    </v-container>
  </v-container>
</template>

<script>
import answer from '@/data/answer'
import { addScoreResult } from './../plugins/firebase'
import { mapMutations } from 'vuex'
export default {
  components: {
    Editors: () => import('@/components/create/Editor'),
    AddScoreResult: () => import('@/components/create/AddScoreResult'),
  },
  data() {
    return {
      type: [],
      contents: '',
      setTemplate: '',
      list: [],
      localStorageData: '',
      typeitems: [2, 3, 4, 5, 6, 7, 8, 9, 10],
      typeArray: [],
      typeArrayResultName: [],
    }
  },
  mounted() {
    // this.type =
    this.type = 4
    this.typeArray = [1, 2, 3, 4]
    const getLocaltheme = JSON.parse(localStorage.getItem('theme'))

    this.contents = `<p>당신의 <strong>${getLocaltheme.title}</strong>의 결과는... !</p> <br> @유형이름템플릿@ 입니다.<br>이 유형은`
  },
  methods: {
    ...mapMutations('common', ['setLoading']),
    copyTemplate() {
      this.setTemplate = this.$store.getters['qna/getTemplate']
    },
    saveResult() {
      // 로딩
      this.setLoading(true)

      this.$store.dispatch('qna/actAddScoreResult', this.$route.params.themeId)

      setTimeout(() => {
        this.setLoading(false)
        this.$router.push(`/test/${this.$route.params.themeId}`)
      }, 2000)
    },
    plusType() {
      this.type += 1
      this.typeArray.push(this.type)
    },
    minusTypeForm(val) {
      if (this.typeArray.length == 1) {
        return
        // this.typeArray.push(1)
      }
      const findItem = this.typeArray.find(item => {
        return item == val
      })
      const index = this.typeArray.indexOf(findItem)
      this.typeArray.splice(index, 1)
    },
  },
  watch: {
    typeArray(val) {
      console.log('watchval', val)
      val.forEach(item => {
        console.log('item', item)

        const index = 'result' + item
        console.log(index)

        const resultvalue = localStorage.getItem(index)
        this.typeArrayResultName[item] = resultvalue
        console.log(resultvalue)
        console.log(this.typeArrayResultName[item])
      })
    },
  },
}
</script>

<style></style>
