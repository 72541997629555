import Vue from 'vue'
import * as firebase from 'firebase/app'
import {
  getFirestore,
  collection,
  addDoc,
  query,
  where,
  orderBy,
  getDoc,
  getDocs,
  doc,
  updateDoc,
  increment,
  deleteDoc,
  setDoc,
} from 'firebase/firestore'
import firebaseConfig from '../../firebaseConfig'
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  onAuthStateChanged,
  reauthenticateWithCredential,
  EmailAuthProvider,
  sendPasswordResetEmail,
  signOut,
} from 'firebase/auth'
import { getStorage, ref, getDownloadURL, list } from 'firebase/storage'

const app = firebase.initializeApp(firebaseConfig)

/** ********************** theme ********************** */
/** theme
 *  method: READ
 *  return: array
 */
export async function getRanking() {
  const db = getFirestore(app)
  const storage = getStorage()
  const q = query(collection(db, 'theme'), orderBy('count', 'desc'))
  const querySnapshot = await getDocs(q)
  const res = []
  await querySnapshot.forEach(doc => {
    const docData = doc.data()
    docData.id = doc.id
    getDownloadURL(ref(storage, docData.thumb)).then(url => {
      docData.thumburl = url
      res.push(docData)
    })
  })
  return res
}

// export async functioNet
/** theme
 *  method: READ
 *  return: object
 */
export async function gettheme(themeId) {
  const db = getFirestore(app)
  const storage = getStorage()

  const res = []
  const choicelist = []
  const docRef = doc(db, 'theme', themeId)
  const docSnap = await getDoc(docRef)
  const docData = docSnap.data()
  const info = docData

  let hasChoice = false
  await getDownloadURL(ref(storage, docData.thumb)).then(url => {
    info.thumburl = url
  })

  const q = query(collection(db, 'qlist'), where('theme', '==', themeId))
  const querySnapshot = await getDocs(q)
  querySnapshot.forEach(doc => {
    const docData = doc.data()
    docData.id = doc.id
    docData.choicelist = []
    if (docData.selectKey == '0') {
      hasChoice = true
    }

    res.push(docData)
  })

  if (hasChoice) {
    const choice = query(
      collection(db, 'choicelist'),
      where('theme', '==', themeId)
    )
    const choiceSnapshot = await getDocs(choice)
    choiceSnapshot.forEach(doc => {
      const choiceData = doc.data()
      choicelist.push(choiceData)
      // res.push(choicelist)
    })
  }

  return { info: info, qlist: res, choicelist: choicelist }
}

/** theme
 *  method: CREATE
 *  return:
 */
export async function addTheme(payload) {
  const db = getFirestore(app)
  try {
    const doc = await addDoc(collection(db, 'theme'), {
      title: payload.title,
      content: payload.content,
      pd: payload.pd,
      count: 0,
      thumb: payload.thumb,
    })
    return doc.id
  } catch (e) {
    console.error('Error adding document: ', e)
    return -1
  }
}

/** theme
 *  method: UPDATE
 */
export async function updateCount(themeId) {
  const db = getFirestore(app)
  try {
    const testRef = doc(db, 'theme', themeId)
    await updateDoc(testRef, {
      count: increment(1),
    })
  } catch (e) {
    console.error('Error adding document: ', e)
    return -1
  }
}

/** theme
 *  method: DELETE
 */
export async function deletetheme(themeId) {
  const db = getFirestore(app)
  const subjetcRef = doc(db, 'theme', themeId)

  const quesRef = query(collection(db, 'qlist'), where('theme', '==', themeId))
  const docSnap = await getDocs(quesRef)
  docSnap.forEach(doc => {
    deleteQs(doc.id)
  })

  const choicesRef = query(
    collection(db, 'choicelist'),
    where('theme', '==', themeId)
  )
  const choiceDocSnap = await getDocs(choicesRef)
  choiceDocSnap.forEach(doc => {
    deleteChoices(doc.id)
  })
  try {
    await deleteDoc(subjetcRef)
    return { code: 'SUCCESS' }
  } catch (e) {
    return { code: 'ERROR' }
  }
}

/** ********************** ASK ********************** */
/** Ask
 *  method: CREATE
 *  return:
 */
export async function addQ(payload) {
  const insertQueData = {
    theme: payload.theme,
    q: payload.q,
    typeOf: payload.typeOf,
    OorX: payload.OorX,
    selectKey: payload.selectKey,
    special: payload.special,
  }
  if (payload.OorX) {
    insertQueData.OorX = payload.OorX
  }
  const db = getFirestore(app)
  try {
    await setDoc(doc(db, 'qlist', payload.qid), insertQueData)
    return 1
  } catch (e) {
    console.log(e)
    return -1
  }
}

export async function addChoice(payload) {
  const insertChoiceData = {
    theme: payload.theme,
    qid: payload.qid,
    choiceText: payload.choiceText,
    choicePoint: payload.choicePoint,
  }
  const db = getFirestore(app)
  try {
    await addDoc(collection(db, 'choicelist'), insertChoiceData)
    return 1
  } catch (e) {
    console.log(e)
    return -1
  }
}

/** Ask
 *  method: DELETE
 */
async function deleteQs(docid) {
  const db = getFirestore(app)
  const QuesRef = doc(db, 'qlist', docid)
  try {
    await deleteDoc(QuesRef)
    return { code: 'SUCCESS' }
  } catch (e) {
    return { code: 'ERROR' }
  }
}

async function deleteChoices(docid) {
  const db = getFirestore(app)
  const QuesRef = doc(db, 'choicelist', docid)
  try {
    await deleteDoc(QuesRef)
    return { code: 'SUCCESS' }
  } catch (e) {
    return { code: 'ERROR' }
  }
}
/** ********************** RESULT ********************** */

/** Result
 *  method: READ
 *  return: object
 */
export async function getResult(payload) {
  const db = getFirestore(app)
  let q = ''
  if (Number(payload.result)) {
    q = query(
      collection(db, 'testresult'),
      where('theme', '==', payload.themeId)
    )
  } else {
    q = query(
      collection(db, 'testresult'),
      where('theme', '==', payload.themeId),
      where('result', '==', payload.result)
    )
  }
  // const q = query(
  //   collection(db, 'testresult'),
  //   where('theme', '==', payload.themeId),
  //   where('result', '==', payload.result)
  // )
  const querySnapshot = await getDocs(q)
  const res = []
  querySnapshot.forEach(doc => {
    const docData = doc.data()

    docData.id = doc.id
    res.push(docData)
  })
  return await res
}

/** Result
 *  method: CREATE
 */
export async function addResult(payload) {
  console.log(payload)

  const insertResultData = {
    theme: payload.theme,
    type: payload.type,
    from: payload.from ? payload.from : '',
    to: payload.to ? payload.to : '',
    result: payload.result,
    content: payload.content,
  }

  const db = getFirestore(app)
  try {
    const doc = addDoc(collection(db, 'testresult'), insertResultData)

    return doc.id
  } catch (e) {
    console.error('Error adding document: ', e)
    return -1
  }
}

/** ********************** COUNT ********************** */
/** Count
 *  method: READ
 *  return: object
 */
export async function getCountList() {
  const db = getFirestore(app)
  const res = {}
  const today = new Date()
  const fullyear = today.getFullYear()
  const month = today.getMonth() + 1
  const day = today.getDate()
  const date = fullyear + '. ' + month + '. ' + day + '.'

  // today
  const todayRef = query(collection(db, 'countlist'), where('date', '==', date))
  const todayDocSnap = await getDocs(todayRef)
  todayDocSnap.forEach(doc => {
    const docData = doc.data()
    res.todayVisit = docData.visit
  })

  // total
  const totalRef = query(collection(db, 'countlist'))
  const totalDocSnap = await getDocs(totalRef)
  let totalCount = 0
  totalDocSnap.forEach(doc => {
    const docData = doc.data()
    if (typeof docData.visit === 'number') {
      totalCount += docData.visit
    }
  })
  res.totalVisit = totalCount

  // themeCount
  const totalTestRef = query(collection(db, 'theme'))
  const totalTestDocSnap = await getDocs(totalTestRef)

  let totalTry = 0
  totalTestDocSnap.forEach(doc => {
    const docData = doc.data()
    if (typeof docData.count === 'number') {
      totalTry += docData.count
    }
  })
  res.totalTry = totalTry
  return res
}
/** Count
 *  method: CREATE
 *  return: object
 */
export async function addTodayVisit(today) {
  const db = getFirestore(app)
  try {
    const todayRef = query(
      collection(db, 'countlist'),
      where('date', '==', today)
    )
    const todayDocSnap = await getDocs(todayRef)

    if (todayDocSnap.docs.length == 0) {
      const doc = await addDoc(collection(db, 'countlist'), {
        date: today,
        visit: 1,
      })
    } else {
      const testRef = doc(db, 'countlist', todayDocSnap.docs[0].id)
      await updateDoc(testRef, {
        visit: increment(1),
      })
    }
  } catch (e) {
    console.error('Error: ', e)
    return -1
  }
}
/** ********************** USER ********************** */
/** User
 *  method: READ
 *  return: array
 */
export async function FindUserList() {
  const db = getFirestore(app)
  const querySnapshot = await getDocs(collection(db, 'user'))
  const res = []
  if (querySnapshot) {
    querySnapshot.forEach(doc => {
      res.push(doc.id)
    })
  }
  return res
}

/** User
 *  method: CREATE
 *  return:
 */
export async function addUser(payload) {
  const db = getFirestore(app)
  try {
    const doc = await addDoc(collection(db, 'user'), {
      uid: payload.uid,
      email: payload.email,
    })

    return doc.id
  } catch (e) {
    console.error('Error adding document: ', e)
    return -1
  }
}

/** User-auth
 *  method: CREATE
 *  return:
 */
export async function signup(newUser) {
  const auth = getAuth()
  try {
    const test = await createUserWithEmailAndPassword(
      auth,
      newUser.email,
      newUser.password
    )
    const user = test.user
    const result = await addUser({ uid: user.uid, email: user.email })

    sessionStorage.setItem('try-me', user.uid)

    const returnCode = 'SUCCESS'
    const res = { user, result, returnCode }
    return res
  } catch (e) {
    const res = 'ERROR'
    return res
  }
}

/** User-auth
 *  method: READ
 *  return:
 */
export async function signin(userinfo) {
  const auth = getAuth()

  try {
    const res = await signInWithEmailAndPassword(
      auth,
      userinfo.email,
      userinfo.password
    )
    const user = res.user

    sessionStorage.setItem('try-me', user.uid)

    if (user.email == 'test@test.com') {
      sessionStorage.setItem('admin', user.uid)
    }
    return { user: user, code: 'SUCCESS' }
  } catch (e) {
    return { user: null, code: 'ERROR' }
  }
}

/** User-auth
 *  method:
 *  return:
 */
export async function logout() {
  const auth = getAuth()
  signOut(auth)
    .then(() => {})
    .catch(e => {
      console.log(e)
    })
}
export async function getLoginState() {
  const auth = getAuth()
  onAuthStateChanged(auth, user => {
    if (user) {
      const uid = user.uid
      // ...
    } else {
      // User is signed out
      // ...
    }
  })
}
export async function getUserInfo() {
  const auth = getAuth()
  const user = auth.currentUser

  const db = getFirestore(app)
  const userRef = query(collection(db, 'user'), where('uid', '==', user.uid))
  const docSnap = await getDocs(userRef)
  const res = []
  docSnap.forEach(doc => {
    const docData = doc.data()

    res.push(docData)
  })
  return res
}
export async function googleLogin() {
  const provider = new GoogleAuthProvider()
  provider.addScope('https://www.googleapis.com/auth/contacts.readonly')
}

/** Password Reset
 *  method:
 *  return:
 */
export async function getNewPassword(email) {
  const auth = getAuth()
  try {
    await sendPasswordResetEmail(auth, email)

    return 'SUCCESS'
  } catch (e) {
    console.log(e)
    return 'ERROR'
  }
}

export async function updateCol() {
  const list = []
  const db = getFirestore(app)
  try {
    const todayRef = query(
      collection(db, 'testresult'),
      where('theme', '==', 'VkFqaprAMMGwloErugKW')
    )
    const todayDocSnap = await getDocs(todayRef)

    if (todayDocSnap.docs.length != 0) {
      console.log('a')
      todayDocSnap.forEach(doc => {
        console.log(doc.id)
        list.push(doc.id)
        // const testRef = doc(db, 'testresult', doc.id)
        // updateDoc(testRef, {
        //   type: 'MBTI',
        // })
      })
      list.forEach(item => {
        const testRef = doc(db, 'testresult', item)
        updateDoc(testRef, {
          type: 'MBTI',
        })
      })
    }
  } catch (e) {
    console.error('Error: ', e)
    return -1
  }
}

// updateCol()
