<template>
  <v-container>
    <v-card
      class="mx-auto my-12"
      max-width="600"
    >
      <v-container>
        <v-tabs
          v-model="tabs"
          fixed-tabs
        >
          <v-tabs-slider />
          <v-tab
            href="#mobile-tabs-5-1"
            class="primary--text"
          >
            <v-icon color="primary">
              mdi-login
            </v-icon>
            sign in
          </v-tab>

          <v-tab
            href="#mobile-tabs-5-2"
            class="primary--text"
          >
            <v-icon color="primary">
              mdi-account-plus
            </v-icon>
            sign-up
          </v-tab>
        </v-tabs>
        <v-tabs-items
          v-model="tabs"
        >
          <v-tab-item
            v-for="i in 2"
            :key="i"
            :value="'mobile-tabs-5-' + i"
            transition="false"
          >
            <v-card flat>
              <!-- <v-card-text v-text="text" /> -->
              <v-row v-if="tabs == 'mobile-tabs-5-1'">
                <signin />
              </v-row>
              <v-row
                v-else
              >
                <signup />
              </v-row>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>

  export default {
    components: {
      Signin: () => import('@/components/user/SignIn'),
      Signup: () => import('@/components/user/SignUp'),
    },
    data: () => ({
      tabs: null,
      text: 'abcd',
    }),
    mounted () {
      if (this.$router.currentRoute.path === '/signup') {
        this.tabs = 'mobile-tabs-5-2'
      }
    },
  }
</script>
