<template>
  <v-container>
    <v-container>
      <v-card>
        <v-card-title> 질문유형, 답변타입 일괄 수정 </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <v-radio-group
                  v-model="setAllSelectType"
                  column
                >
                  <v-radio
                    label="각 질문 별로 템플릿 답변 타입 중에서 선택"
                    color="success"
                    value="no"
                    :disabled="isOX"
                  />
                  <v-radio
                    label="질문별 텍스트 등록"
                    color="success"
                    value="0"
                    :disabled="isOX"
                  />
                  <v-radio
                    label="질문별 이미지 등록(구현 예정)"
                    color="success"
                    value="1"
                    :disabled="isOX"
                  />
                  <v-radio
                    label="모두 YES / NO"
                    color="success"
                    value="10"
                    :disabled="isOX"
                  />
                  <v-radio
                    label="모두 네 / 아니오"
                    color="success"
                    value="11"
                    :disabled="isOX"
                  />
                  <v-radio
                    label="모두 동의 / 비동의"
                    color="success"
                    value="12"
                    :disabled="isOX"
                  />
                  <v-radio
                    label="모두 그렇다 / 그렇지 않다"
                    color="success"
                    value="13"
                    :disabled="isOX"
                  />
                  <!-- <v-radio
                    label="O / X"
                    color="success"
                    value="14"
                  /> -->
                </v-radio-group>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-container>

    <div
      v-for="i in a.length"
      :key="i"
      :value="'asdf' + i"
      transition="false"
    >
      <addque
        :result-type="resultType"
        :setting="i"
        :all-select-type="setAllSelectType"
      />
    </div>
    <v-container>
      <v-row>
        <v-col cols="3">
          <v-btn
            block
            to="/"
          >
            메인으로
          </v-btn>
        </v-col>
        <v-col cols="9">
          <v-btn
            class="elevation-4"
            color="primary"
            block
            @click="submit()"
          >
            결과 유형 입력하기
            <v-icon right> mdi-arrow-right </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <!-- IsScore 점수 스낵바 -->
    <!--  @@@todo -->
    <!-- <v-snackbar
      v-model="snackbar"
      :timeout="-1"
      :value="true"
      color="blue"
      top
    >
      입력된 질문 수 : 10개 중 4개 (57%)<br>
      최종결과의 최저점/최고점 (30/100)

      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
        />
      </template>
    </v-snackbar> -->
  </v-container>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  components: {
    Addque: () => import('@/components/create/Addque'),
  },
  data() {
    return {
      // qcount: [],
      pd: '',
      title: '',
      content: '',
      a: [],
      list: [],
      setAllSelectType: 'no',
      isOX: false,
      thumb: '',
      multiLine: true,
      snackbar: true,
      text: 'I am a multi-line snackbar.',
      choiceQlist: [],
    }
  },
  watch: {
    setAllSelectType(val) {
      if (val === 14) {
        this.resultType = 'OX'
      }
    },
  },

  beforeMount() {
    const getLocaltheme = JSON.parse(localStorage.getItem('theme'))
    this.title = getLocaltheme.title
    this.pd = getLocaltheme.pd
    this.resultType = getLocaltheme.resultType
    this.content = getLocaltheme.content
    this.thumb = localStorage.getItem('thumb')
    for (let index = 1; index < Number(getLocaltheme.qcount) + 1; index++) {
      this.a.push('q' + index)
    }
  },
  mounted() {
    if (this.resultType === 'OX') {
      this.setAllSelectType = '14'
      this.isOX = true
    } else if (this.resultType === 'SCORE') {
      this.setAllSelectType = '0'
    }
  },
  methods: {
    ...mapMutations('common', ['setLoading']),

    submit() {
      this.setLoading(true)

      this.a.forEach((item, index, arr) => {
        const randomStr = Math.random().toString(36).substring(2, 12)

        const newIndex = index + 1
        const q = localStorage.getItem(item)
        const typeOf = localStorage.getItem('type' + newIndex)
        const OorX = localStorage.getItem('OorX' + newIndex)

        if (this.setAllSelectType != 'no') {
          this.list.push({
            qid: randomStr,
            q: q,
            typeOf: typeOf,
            selectKey: this.setAllSelectType,
            OorX: OorX,
            special: '',
            index: newIndex,
          })
        } else {
          const selectKey = localStorage.getItem('selectKey' + newIndex)
          this.list.push({
            qid: randomStr,
            q: q,
            typeOf: typeOf,
            selectKey: selectKey,
            OorX: OorX,
            special: '',
            index: newIndex,
          })
        }
        if (this.setAllSelectType == 0) {
          const choiceCount = localStorage.getItem('choiceCount' + newIndex)
          const newchoiceCount = Number(choiceCount) + 1
          for (let i = 1; i < newchoiceCount; i++) {
            const choiceTextIndex = newIndex + '_choiceText' + i
            const choicePointIndex = newIndex + '_choicePoint' + i
            const choiceText = localStorage.getItem(choiceTextIndex)
            const choicePoint = localStorage.getItem(choicePointIndex)

            this.choiceQlist.push({
              qid: randomStr,
              choiceText,
              choicePoint,
              index: i,
            })
          }
        }
      })

      this.$store
        .dispatch('qna/addTheme', {
          theme: {
            title: this.title,
            pd: this.$store.state.user.user.uid,
            resultType: this.resultType,
            content: this.content,
            thumb: this.thumb,
          },
          qlist: this.list,
          choicelist: this.choiceQlist,
        })
        .then(() => {
          if (this.$store.state.qna.createdthemeId) {
            this.setLoading(false)
            if (this.resultType === 'MBTI') {
              this.$router.push(
                `/addresult/${this.$store.state.qna.createdthemeId}`
              )
            } else {
              setTimeout(() => {
                this.setLoading(false)
                if (this.resultType === 'OX') {
                  this.$router.push(
                    `/test/${this.$store.state.qna.createdthemeId}`
                  )
                } else if (this.resultType === 'SCORE') {
                  this.$router.push(
                    `/test/${this.$store.state.qna.createdthemeId}`
                  )
                } else if (this.resultType === 'SCORE') {
                  this.$router.push(
                    `/test/${this.$store.state.qna.createdthemeId}`
                  )
                }
              }, 2000)
            }
          }
        })
    },
  },
}
</script>

<style></style>
