import {
  getUser,
  getRanking,
  gettheme,
  addTheme,
  addQ,
  updateCount,
  deletetheme,
  getImg,
  addChoice,
  addResult,
} from './../plugins/firebase'
export default {
  namespaced: true,
  state: {
    list: [],
    ranking: [],
    qs: [],
    createdthemeId: '',
    creating: {
      scoreResult: [],
      template: '',
    },
  },
  getters: {
    getRanking(state) {
      return state.ranking
    },
    getQs(state) {
      return state.qs
    },
    getTotalCount(state, getters) {
      return getters.getQs.length
    },
    getCreatedthemeId(state) {
      return state.createdthemeId
    },
    getTemplate(state) {
      return state.creating.template
    },
  },
  mutations: {
    setUsers(state, list) {
      state.list = list
    },
    setRanking(state, ranking) {
      state.ranking = ranking
    },
    setQs(state, qs) {
      state.qs = qs
    },
    setCreatedthemeId(state, themeId) {
      state.createdthemeId = themeId
    },
    setScoreResult(state, object) {
      const res = state.creating.scoreResult.findIndex(
        item => item.num == object.num
      )
      if (res > -1) {
        state.creating.scoreResult[res] = {
          ...state.creating.scoreResult[res],
          ...object,
        }
      } else {
        state.creating.scoreResult.push(object)
      }
    },
    setTemplate(state, object) {
      state.creating.template = object
    },
  },
  actions: {
    async fetchRanking(context) {
      await getRanking()
        .then(res => {
          context.commit('setRanking', res)
        })
        .catch(e => {
          console.log(e)
        })
    },
    async fetchQs({ commit }, payload) {
      const res = await gettheme(payload.themeId)

      res.choicelist.forEach(choice => {
        res.qlist.forEach(item => {
          if (choice.qid == item.id) {
            item.choicelist.push(choice)
          }
        })
      })
      commit('setQs', res)

      return res.data
    },
    async addTheme({ state, commit }, payload) {
      try {
        const res = await addTheme(payload.theme)
        await commit('setCreatedthemeId', res)

        await asyncForEach(res, payload.qlist, addQ)

        if (payload.choicelist) {
          await asyncForEach(res, payload.choicelist, addChoice)
        }
      } catch (e) {
        console.log(e)
      }
    },
    async updateCount({ commit }, themeId) {
      await updateCount(themeId)
    },
    async deletetheme({ commit }, themeId) {
      try {
        const res = await deletetheme(themeId)
        return 'SUCCESS'
      } catch (e) {
        console.log('e', e)
        return 'ERROR'
      }
    },
    async fetchImg({ commit }, themeId, filename) {
      const res = await getImg(themeId, filename)
    },
    async actSetScoreResult({ commit }, object) {
      commit('setScoreResult', object)
    },
    actSetTemplate({ commit }, object) {
      commit('setTemplate', object)
    },
    async actAddScoreResult({ state, commit }, themeId) {
      if (state.creating.scoreResult.length > 0) {
        await asyncForEach(themeId, state.creating.scoreResult, addResult)

        // await addResult(state.creating.scoreResult)
        // await asyncForEach(res, payload.qlist, addQ)
        // await asyncForEach(res, payload.choicelist, addChoice)
      }
    },
  },
}

async function asyncForEach(themeId, array, callback) {
  console.table(array)
  for (let i = 0; i < array.length; i++) {
    array[i].theme = themeId
    await callback(array[i])
  }
}
